import qs from 'qs'
import mixpanel from 'shared-components/utils/mixpanel'
import apiClient from 'shared-components/utils/ApiClient'
import { initDates } from './pinning'
import {
  closeAndResetRsvpModal,
  onChangeAddGuestName,
  openRsvpOnStep,
  rsvp,
  rsvpWaitList,
  setEmail,
  setNewGuestStatus,
  setNewGuestWaitingList,
  showConfetti,
} from './rsvp'
import { setSnackbar } from 'shared-components/redux/notifications/actions'
import {
  getCurrentEvent,
  getCurrentJoinOption,
  getShouldRespondToQuestions,
} from 'selectors/event'
import { eventIsExternal, getAmountNotifiedText } from '../utils/eventFunctions'
import dayjs from 'dayjs'
import i18n from 'config/i18n'
import { RsvpStatus } from 'types/types'
import { closeSignInModal, openModal, openSignInModal } from './modals'

export const FETCH_EVENT = 'FETCH_EVENT'
export const FETCH_CHAT_DIGEST = 'FETCH_CHAT_DIGEST'
export const PATCH_EVENT = 'PATCH_EVENT'
export const SET_CURRENT_GUEST = 'SET_CURRENT_GUEST'
export const SET_STATUS = 'SET_STATUS'
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'
export const SET_CURRENT_TICKET_CODE = 'SET_CURRENT_TICKET_CODE'
export const SET_CURRENT_SELECTED_USER = 'SET_CURRENT_SELECTED_USER'
export const SET_DATE_OPTIONS = 'SET_DATE_OPTIONS'
export const SET_LOCAL_GUEST = 'SET_LOCAL_GUEST'
export const REMOVE_LOCAL_GUEST = 'REMOVE_LOCAL_GUEST'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const REMOVE_RSVP = 'REMOVE_RSVP'
export const RSVP_EVENT = 'RSVP_EVENT'
export const WAITLIST_EVENT = 'WAITLIST_EVENT'
export const HYPE_EVENT = 'HYPE_EVENT'
export const CHANGE_EVENT = 'CHANGE_EVENT'
export const CHANGE_DRAFT = 'CHANGE_DRAFT'
export const POST_MESSAGE = 'POST_MESSAGE'
export const INVITE_USER = 'INVITE_USER'
export const LIKE_EMOJI_MESSAGE = 'LIKE_EMOJI_MESSAGE'
export const SET_DRAFT = 'SET_DRAFT'
export const SET_OPEN_BASH_FROM = 'SET_OPEN_BASH_FROM'
export const PIN_DATE = 'PIN_DATE'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ADD_IMAGES = 'ADD_IMAGES'
export const SET_IMAGES = 'SET_IMAGES'
export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const CHANGE_MESSAGE = 'CHANGE_MESSAGE'
export const PATCH_GUEST_FROM_INFO_SHEET = 'PATCH_GUEST_FROM_INFO_SHEET'
export const DELETE_GUEST_FROM_INFO_SHEET = 'DELETE_GUEST_FROM_INFO_SHEET'
export const ADD_GUESTS_BY_STATUS = 'ADD_GUESTS_BY_STATUS'
export const REMOVE_LAST_GUEST_MESSAGE = 'REMOVE_LAST_GUEST_MESSAGE'
export const UPDATE_ERROR = 'UPDATE_ERROR'
export const REMOVE_ALL_MESSAGES = 'REMOVE_ALL_MESSAGES'
export const LIKE_EVENT = 'LIKE_EVENT'
export const DISLIKE_EVENT = 'DISLIKE_EVENT'
export const REQUEST_JOIN = 'REQUEST_JOIN'
export const DELETE_EVENT = 'DELETE_EVENT'
export const SET_LOCATION_BEFORE_EDIT = 'SET_LOCATION_BEFORE_EDIT'
export const REMOVE_RSVP_ERROR = 'REMOVE_RSVP_ERROR'
export const SET_USERS_WITH_EVENTS = 'SET_USERS_WITH_EVENTS'
export const FETCH_EVENT_CHAT = 'FETCH_EVENT_CHAT'
export const FETCH_EVENT_IMAGES = 'FETCH_EVENT_IMAGES'
export const FETCH_THEME_IMAGES = 'FETCH_THEME_IMAGES'
export const FETCH_COLOUR_SCHEMES = 'FETCH_COLOUR_SCHEMES'
export const FETCH_FONTS = 'FETCH_FONTS'
export const FETCH_USER_GUEST_QUESTION_RESPONSES =
  'FETCH_USER_GUEST_QUESTION_RESPONSES'
export const SET_ACTIVE_IMAGE_INDEX = 'SET_ACTIVE_IMAGE_INDEX'
export const SET_MY_GUEST = 'SET_MY_GUEST'
export const SET_SHOW_IMAGES = 'SET_SHOW_IMAGES'
export const REMOVE_PENDING_ORDER = 'REMOVE_PENDING_ORDER'
export const SET_SCRAPE_DRAFT = 'SET_SCRAPE_DRAFT'
export const EDIT_EVENT = 'EDIT_EVENT'
export const SET_RSVP_PENDING = 'SET_RSVP_PENDING'
export const UPDATE_HYPE_SCORE = 'UPDATE_HYPE_SCORE'
export const ADD_GUEST_TO_EVENT = 'ADD_GUEST_TO_EVENT'
export const UPDATE_JOIN_OPTION_AVAILABLE = 'UPDATE_JOIN_OPTION_AVAILABLE'
export const SET_DEFAULT_QUESTIONS = 'SET_DEFAULT_QUESTIONS'

export const setScrapeDraft = (eventDraft) => ({
  type: SET_SCRAPE_DRAFT,
  payload: eventDraft,
})

export const fetchEvent =
  (eventCode, guestCode) => async (dispatch, getState) => {
    await dispatch({
      type: FETCH_EVENT,
      payload: apiClient.event.byCode(eventCode, null, null, guestCode),
    })
  }

export const fetchThemeImages = () => async (dispatch) => {
  await dispatch({
    type: FETCH_THEME_IMAGES,
    payload: apiClient.event.getDefaultImages(),
  })
}

export const fetchColourSchemes = () => async (dispatch) => {
  await dispatch({
    type: FETCH_COLOUR_SCHEMES,
    payload: apiClient.event.getColourSchemes(),
  })
}

export const fetchFonts = () => async (dispatch) => {
  await dispatch({
    type: FETCH_FONTS,
    payload: apiClient.event.getFonts(),
  })
}

export const setUsersWithEvents = (items) => ({
  type: SET_USERS_WITH_EVENTS,
  payload: items,
})

export const fetchChatDigest =
  (eventId, eventCode) => async (dispatch, getState) => {
    const payload = await apiClient.event.getChatDigest(
      eventId,
      eventCode,
      null,
      2,
    )
    dispatch({
      type: FETCH_CHAT_DIGEST,
      payload: payload,
    })
  }

export const fetchEventChat = (chatGroupId) => async (dispatch, getState) => {
  const state = getState()
  if (
    chatGroupId &&
    !state.event.events[state.event.currentEventCode]?.chatLoading &&
    !state.event.events[state.event.currentEventCode]?.chatRejected &&
    !state.event.events[state.event.currentEventCode]?.chatFulfilled
  ) {
    dispatch({
      type: FETCH_EVENT_CHAT,
      payload: apiClient.event.getChat(chatGroupId),
    })
  }
}

export const fetchEventImages = (eventCode) => async (dispatch, getState) => {
  const state = getState()
  const event = state.event.events[eventCode]
  if (event) {
    dispatch({
      type: FETCH_EVENT_IMAGES,
      payload: apiClient.event.getImages(
        event.chatGroup?.id,
        dayjs().toISOString(),
        250,
      ),
    })
  }
}

export const addImages = (images) => ({
  type: ADD_IMAGES,
  payload: images,
})

export const setImages = (images) => ({
  type: SET_IMAGES,
  payload: images,
})

export const setActiveImageIndex = (index) => ({
  type: SET_ACTIVE_IMAGE_INDEX,
  payload: index,
})

export const setMyGuest = (status) => ({
  type: SET_MY_GUEST,
  payload: status,
})

export const setShowImages = (showImages) => ({
  type: SET_SHOW_IMAGES,
  payload: showImages,
})

export const updateError = (isError) => ({
  type: UPDATE_ERROR,
  payload: isError,
})

export const cancelOrder = (ticketOrderId, guestCode) => async (dispatch) => {
  await dispatch({
    type: REMOVE_PENDING_ORDER,
    payload: apiClient.tickets.cancelOrder(ticketOrderId, guestCode),
  })
}

export const cancelEvent =
  (event, notify = true) =>
  async (dispatch) => {
    // event.state = 'CANCELED'
    const patchEvent = async () => {
      const response = await apiClient.event.edit(
        { ...event, state: 'CANCELED' },
        event.id,
        notify,
      )
      return response.event
    }
    dispatch({
      type: PATCH_EVENT,
      payload: patchEvent(),
    })
  }

export const deleteEvent = (eventId, eventCode) => async (dispatch) => {
  await dispatch({
    type: REMOVE_EVENT,
    payload: {
      promise: apiClient.event.delete(eventId),
      data: eventCode,
    },
  })
}

export const localOnEventLand = () => (dispatch, getState) => {
  const event = getCurrentEvent(getState())
  const properties = {
    'Is Host': !!event.host,
    'Logged In': !!getState().user.user.id,
    Source: getState().event.openBashFrom || 'link',
  }

  const user = getState().user.user
  // apiClient.general.analyticsEventEvent('open-bash', {
  //   eventId: event.id,
  //   userId: user?.id,
  // })
  // mixpanelTrackEventState('Open Bash', event, properties, getState())
  // mixpanel.trackEvent('Open Bash', event, properties)
  mixpanel.unregister('Is Preview')
  dispatch(setOpenBashFrom(null))
  // loadGuestLocal(dispatch, getState)
}

export const loadGuestLocal = () => (dispatch, getState) => {
  const event = getCurrentEvent(getState())
  let savedGuestId = null
  if (typeof window !== 'undefined') {
    savedGuestId = +(
      window.localStorage && window.localStorage.getItem(`guest_${event.code}`)
    )
  }
  if (savedGuestId) {
    const guest = event.guests.find((g) => g.id === savedGuestId)
    if (guest) {
      dispatch({
        type: SET_LOCAL_GUEST,
        payload: savedGuestId,
      })
    }

    dispatch(initDates(event.dateOptions, guest && guest.id))
  }
  dispatch(localOnEventLand())
}

export const removeGuestLocal = () => (dispatch, getState) => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(`guest_${getState().event.currentEventCode}`)
  }
  dispatch({
    type: REMOVE_LOCAL_GUEST,
  })
}

export const setCurrentGuest = (guestId) => ({
  type: SET_CURRENT_GUEST,
  payload: guestId,
})

export const requestJoin = (eventId, requestReason) => async (dispatch) =>
  dispatch({
    type: REQUEST_JOIN,
    payload: apiClient.event.requestJoin(eventId, requestReason),
  })

export const setStatus = (status) => ({
  type: SET_STATUS,
  payload: status,
})

export const changeEvent = (field, value) => ({
  type: CHANGE_EVENT,
  payload: {
    field,
    value,
  },
})

export const likeEvent = () => ({
  type: LIKE_EVENT,
})

export const dislikeEvent = () => ({
  type: DISLIKE_EVENT,
})

export const setDraftPrivacyType = (value) => ({
  type: CHANGE_DRAFT,
  payload: {
    field: 'privacyType',
    value,
  },
})

export const changeDraft = (field, value) => async (dispatch) =>
  dispatch({
    type: CHANGE_DRAFT,
    payload: {
      field,
      value,
    },
  })

export const setLocBeforeEdit = (location) => ({
  type: SET_LOCATION_BEFORE_EDIT,
  payload: location,
})

export const setDraft = (draft) => ({
  type: SET_DRAFT,
  payload: draft,
})

export const resetDraftGuests = () => ({
  type: SET_DRAFT,
  payload: {
    guests: [
      {
        status: 'GOING',
        name: 'Adam',
        avatarUrl: '/images/dummyprofiles/adam.png',
        gaveDateOptions: true,
        user: {
          name: 'Adam',
          avatarUrl: '/images/dummyprofiles/adam.png',
        },
      },
      {
        status: 'GOING',
        name: 'Maggie',
        avatarUrl: '/images/dummyprofiles/maggie.png',
        gaveDateOptions: true,
        user: {
          name: 'Maggie',
          avatarUrl: '/images/dummyprofiles/maggie.png',
        },
      },
      {
        status: 'GOING',
        name: 'Christie',
        avatarUrl: '/images/dummyprofiles/christie.png',
        gaveDateOptions: true,
        user: {
          name: 'Christie',
          avatarUrl: '/images/dummyprofiles/christie.png',
        },
      },
      {
        status: 'MAYBE',
        name: 'Eric',
        avatarUrl: '/images/dummyprofiles/eric.png',
        gaveDateOptions: true,
        user: {
          name: 'Eric',
          avatarUrl: '/images/dummyprofiles/eric.png',
        },
      },
      {
        status: 'CANT',
        name: 'Anna',
        avatarUrl: '/images/dummyprofiles/anna.png',
        gaveDateOptions: true,
        user: {
          name: 'Anna',
          avatarUrl: '/images/dummyprofiles/anna.png',
        },
      },
    ],
    statusCounts: {
      going: 3,
      maybe: 1,
      cant: 1,
      invited: 0,
    },
    imageUrl: '',
  },
})

export const resetDraft = (draft) => ({
  type: SET_DRAFT,
  payload: {
    privacyType: 'PRIVATE',
    name: '',
    startDate: null,
    endDate: null,
    location: '',
    callLink: '',
    description: '',
    covidMeasures: '',
    responseType: 'DEFAULT',
    limitNumberOfGuests: false,
    expectedNumberOfGuests: 0,
    extraAttendees: 0,
    guestsCanSeeGuests: true,
    guestsCanInvite: true,
    visibleOnProfile: true,
    ticketOptions: [],
    type: 'INVITE',
    ticketLink: null,
    ticketPrice: null,
    ticketCurrency: 'EUR',
    dateToBeAnnounced: false,
    tags: [],
    organisers: [{ name: '' }],
    category: 'OTHER',
    hosts: [
      // {
      //   type: 'GUEST',
      //   model: {
      //     user: { name: '' },
      //   },
      // },
    ],
    guests: [
      {
        status: 'GOING',
        name: 'Adam',
        avatarUrl: '/images/dummyprofiles/adam.png',
        gaveDateOptions: true,
        user: {
          name: 'Adam',
          avatarUrl: '/images/dummyprofiles/adam.png',
        },
      },
      {
        status: 'GOING',
        name: 'Maggie',
        avatarUrl: '/images/dummyprofiles/maggie.png',
        gaveDateOptions: true,
        user: {
          name: 'Maggie',
          avatarUrl: '/images/dummyprofiles/maggie.png',
        },
      },
      {
        status: 'GOING',
        name: 'Christie',
        avatarUrl: '/images/dummyprofiles/christie.png',
        gaveDateOptions: true,
        user: {
          name: 'Christie',
          avatarUrl: '/images/dummyprofiles/christie.png',
        },
      },
      {
        status: 'MAYBE',
        name: 'Eric',
        avatarUrl: '/images/dummyprofiles/eric.png',
        gaveDateOptions: true,
        user: {
          name: 'Eric',
          avatarUrl: '/images/dummyprofiles/eric.png',
        },
      },
      {
        status: 'CANT',
        name: 'Anna',
        avatarUrl: '/images/dummyprofiles/anna.png',
        gaveDateOptions: true,
        user: {
          name: 'Anna',
          avatarUrl: '/images/dummyprofiles/anna.png',
        },
      },
    ],
    statusCounts: {
      going: 3,
      maybe: 1,
      cant: 1,
      invited: 0,
    },
    imageUrl: '',
    imageUrls: null,
    chatGroup: {
      state: 'ADMIN_ONLY',
    },
  },
})

export const rsvpFlow =
  (status, location, stripeCustomerId) => async (dispatch, getState) => {
    const state = getState()
    const inEventWidget = state.rsvp.inEventWidget
    const inOrgWidget = state.rsvp.inOrgWidget
    location = inEventWidget ? 'web_single_widget' : location
    location = inOrgWidget ? 'web_calendar_widget' : location
    const currentStep = state.modals.open.rsvp ? state.rsvp.currentStep : null
    const isLoggedIn = !!state.user?.user?.id
    const selectedTicketOption = getCurrentJoinOption(state)
    const cantOrMaybe =
      status === RsvpStatus.CANT || status === RsvpStatus.MAYBE
    const event = getCurrentEvent(state)
    const invited = event.invitedBy != null
    const showStripe =
      selectedTicketOption?.ticketOption?.approvalRequired &&
      ((selectedTicketOption?.price != null &&
        selectedTicketOption?.price > 0) ||
        (selectedTicketOption.ticketOption?.price != null &&
          selectedTicketOption.ticketOption?.price > 0)) &&
      !cantOrMaybe
    // const showStripe =
    //   selectedTicketOption?.ticketOption?.approvalRequired &&
    //   selectedTicketOption?.ticketOption?.price != null &&
    //   selectedTicketOption?.ticketOption?.price > 0 &&
    //   !cantOrMaybe
    const approvalRequired =
      selectedTicketOption?.ticketOption?.approvalRequired && !cantOrMaybe
    const free =
      (selectedTicketOption?.ticketOption?.chooseYourOwnPrice &&
        (selectedTicketOption.price == null ||
          selectedTicketOption.price === 0)) ||
      (!selectedTicketOption?.ticketOption?.chooseYourOwnPrice &&
        (selectedTicketOption?.ticketOption.price === 0 ||
          selectedTicketOption?.ticketOption.price == null))

    if (event.myGuest?.emailAddress) {
      dispatch(setEmail(event.myGuest?.emailAddress))
    }
    if (event?.myGuest?.name) {
      dispatch(onChangeAddGuestName(event.myGuest?.name))
    }

    dispatch(setNewGuestStatus(approvalRequired ? 'REQUESTED' : status))

    // Force login if email is required
    if (selectedTicketOption?.ticketOption?.verifiedGuestsOnly && !isLoggedIn) {
      dispatch(openSignInModal(null, null, true, stripeCustomerId, true))
      return
    }

    // const hasPickedBashOrWidget = getHasPickedBashOrWidget(state)
    // if (
    //   inWidget &&
    //   !hasPickedBashOrWidget &&
    //   currentStep !== 'bash-or-widget'
    // ) {
    //   dispatch(openRsvpOnStep('bash-or-widget'))
    //   return
    // }

    const shouldRespondToQuestions =
      getShouldRespondToQuestions(state) &&
      !(status === 'MAYBE' || status === 'CANT')

    if (shouldRespondToQuestions && currentStep !== 'questions') {
      dispatch(openRsvpOnStep('questions'))
      return
    }

    const personalQuestions =
      selectedTicketOption?.ticketOption?.questions?.filter(
        (q) => q.question?.profileType,
      )
    if (
      personalQuestions &&
      personalQuestions.length > 0 &&
      currentStep !== 'info' &&
      !(status === 'MAYBE' || status === 'CANT')
    ) {
      dispatch(openRsvpOnStep('info'))
      return
    }

    if (showStripe && !stripeCustomerId) {
      dispatch(openRsvpOnStep('info'))
      return
    }

    if (
      currentStep === 'info' ||
      (approvalRequired && free && isLoggedIn) ||
      (isLoggedIn && !invited) ||
      stripeCustomerId
    ) {
      try {
        await dispatch(
          rsvp(
            isLoggedIn ? null : event.myGuest?.code,
            location,
            stripeCustomerId,
          ),
        )
        dispatch(closeAndResetRsvpModal())
        if (
          (status === 'GOING' || status === 'JOINED') &&
          (eventIsExternal(event) ||
            (event.hasJoinOptions &&
              free &&
              selectedTicketOption?.idInfo?.source !== 'EVENTIX')) &&
          !approvalRequired
        ) {
          dispatch(showConfetti())
          if (event.privacyType === 'PUBLIC') {
            dispatch(openModal('postRsvp'))
          }
        } else {
          if (!event.hasJoinOptions || ['MAYBE', 'CANT'].includes(status)) {
            dispatch(
              setSnackbar('notified', i18n.t('common:thanksForResponse')),
            )
          }
        }
      } catch (error) {
        console.error('Error rsvping:', error)
        dispatch(closeAndResetRsvpModal())
      }
    } else {
      dispatch(openRsvpOnStep('info'))
    }
    dispatch(closeSignInModal())
  }

export const waitlistFlow = (location) => async (dispatch, getState) => {
  dispatch(setNewGuestStatus(null))
  dispatch(setNewGuestWaitingList(true))
  const state = getState()
  const isLoggedIn = !!state.user?.user?.id
  if (isLoggedIn) {
    dispatch(rsvpWaitList(location, null))
  } else {
    dispatch(openRsvpOnStep('info'))
  }
}

export const setDateOptions = (dateOptions) => ({
  type: SET_DATE_OPTIONS,
  payload: dateOptions,
})

const externalpatchGuest = (id, guest) => ({
  type: RSVP_EVENT,
  payload: new Promise((resolve) => {
    setTimeout(
      () =>
        resolve({
          ...guest,
          id,
          gaveDateoptions: !!guest.dateOptions,
        }),
      200,
    )
  }),
})

export const addGuestDownload = (eventCode, guest) => (dispatch) => {
  const params = {
    eventCode,
    distinctId: mixpanel.get_distinct_id(),
    ...guest,
  }
  if (guest.dateOptions) {
    const dateOptions = encodeURI(JSON.stringify(guest.dateOptions))
    window.open(
      `${
        process.env.NEXT_PUBLIC_API_HOST
      }/code/events/guests/downloadDateOptions?${qs.stringify({
        ...params,
        dateOptions,
      })}`,
      '_blank',
    )
  } else {
    window.open(
      `${
        process.env.NEXT_PUBLIC_API_HOST
      }/code/events/guests/download?${qs.stringify(params)}`,
      '_blank',
    )
  }
  return dispatch(externalpatchGuest('NOT_A_POSSIBLE_ID', guest))
}

export const setCurrentEvent = (eventCode) => ({
  type: SET_CURRENT_EVENT,
  payload: eventCode,
})

export const setCurrentTicketCode = (ticketCode) => ({
  type: SET_CURRENT_TICKET_CODE,
  payload: ticketCode,
})

export const setCurrentSelectedUser = (profile) => ({
  type: SET_CURRENT_SELECTED_USER,
  payload: profile,
})

export const postMessage = (message, eventId) => ({
  type: POST_MESSAGE,
  payload: apiClient.event.postMessage(message, eventId),
})

export const deleteMessage = (messageId) => ({
  type: DELETE_MESSAGE,
  payload: messageId,
})

export const addMessage = (message) => ({
  type: ADD_MESSAGE,
  payload: message,
})

export const removeLastGuestMessage = () => ({
  type: REMOVE_LAST_GUEST_MESSAGE,
  payload: null,
})

export const removeAllMessages = () => ({
  type: REMOVE_ALL_MESSAGES,
  payload: null,
})

export const postMessageByGuestCode =
  (eventCode, guestId, privateCode, content) => (dispatch, getState) => {
    if (
      getCurrentEvent(getState()).messages?.find((message) => message.id === -1)
    ) {
      dispatch(removeLastGuestMessage())
      dispatch({
        type: POST_MESSAGE,
        payload: apiClient.event.postMessageByGuestCode(
          eventCode,
          guestId,
          privateCode,
          { text: content },
        ),
      })
    }
  }

export const fetchMessages =
  (eventCode, page, size, guestId) => async (dispatch) => {
    if (!eventCode) return
    const messages = await apiClient.event.getMessages(
      eventCode,
      page,
      size,
      guestId,
    )
    const hasMore = messages.length === size
    dispatch({
      type: FETCH_MESSAGES,
      payload: { messages, eventCode, hasMore, page },
    })
  }

export const changeMessage = (message) => ({
  type: CHANGE_MESSAGE,
  payload: message,
})

export const addMessageWithImage = (message) => ({
  type: ADD_MESSAGE,
  payload: {
    ...message,
    imageUrl: `${process.env.NEXT_PUBLIC_API_HOST}/events/messages/${message.id}/image`,
  },
})

export const inviteUser = (userIds, eventId, asOrganisationId) => ({
  type: INVITE_USER,
  payload: apiClient.event.inviteUser(userIds, eventId, asOrganisationId),
})

export const inviteUserNew = (invitees, eventId, asOrganisationId) => ({
  type: INVITE_USER,
  payload: apiClient.event.inviteUserNew(invitees, eventId, asOrganisationId),
})

export const likeEmojiMessage = (messageId) => ({
  type: LIKE_EMOJI_MESSAGE,
  payload: apiClient.event.likeEmojiMessage(messageId, '🎉'),
})

export const unlikeEmojiMessage = (messageId) => ({
  type: LIKE_EMOJI_MESSAGE,
  payload: apiClient.event.unlikeEmojiMessage(messageId, '🎉'),
})

export const patchEvent = (event, eventId) => ({
  type: PATCH_EVENT,
  payload: apiClient.event.edit(event, eventId),
})

export const setOpenBashFrom = (source) => ({
  type: SET_OPEN_BASH_FROM,
  payload: source,
})

export const removeRsvpError = () => ({
  type: REMOVE_RSVP_ERROR,
})

export const pinDate = (eventId, dateOptionId) => (dispatch, getState) => {
  const event = getCurrentEvent(getState())
  event.guests?.length > 1 &&
    dispatch(setSnackbar('notified', getAmountNotifiedText(event)))
  dispatch({
    type: PIN_DATE,
    payload: apiClient.event.pinDate(eventId, dateOptionId),
  })
}

export const deleteGuest = (guestId, status) => ({
  type: DELETE_GUEST_FROM_INFO_SHEET,
  payload: {
    promise: apiClient.guest.delete(guestId),
    data: { id: guestId, status },
  },
})

export const patchGuest = (guest, body, gc) => ({
  type: PATCH_GUEST_FROM_INFO_SHEET,
  payload: {
    promise: apiClient.guest.patch(guest.id, body, gc),
    data: guest,
  },
})

export const addGuestsByStatus = (guests, status, eventCode) => ({
  type: ADD_GUESTS_BY_STATUS,
  payload: { guests, status, eventCode },
})

export const editEvent = (event) => ({
  type: EDIT_EVENT,
  payload: event,
})

export const updateHypeScore = (eventId, score) => ({
  type: UPDATE_HYPE_SCORE,
  payload: {
    eventId,
    score,
  },
})

export const addGuestToEvent = (eventId, guest) => ({
  type: ADD_GUEST_TO_EVENT,
  payload: {
    eventId,
    guest,
  },
})

export const updateJoinOptionAvailable = (eventId, joinOptionId, amount) => ({
  type: UPDATE_JOIN_OPTION_AVAILABLE,
  payload: {
    eventId,
    joinOptionId,
    amount,
  },
})

export const setDefaultQuestions = (questions) => ({
  type: SET_DEFAULT_QUESTIONS,
  payload: questions,
})
