import { Dayjs } from 'dayjs'
import {
  ExtendedFormQuestionType,
  ExtendedQuestionDto,
} from '../components/RsvpWizard/questions/types'
import { UserDto } from './user'
import { OrganisationDto } from './organisation'
import { IdDto } from './post'
import { GuestDto } from './guest'

export interface EventDto extends IdDto {
  id: number
  name: string | null
  organisation: any | null
  imageUrls: ImageUrls | null
  videoUrl: string | null
  startDate: string | Dayjs | null
  endDate: string | null
  location: string | null
  placeName: string | null
  coordinates: any | null
  address: string | null
  description: string | null
  descriptionMarkdown: string | null
  type: 'INVITE' | 'PINNING'
  statusCounts: StatusCounts | null
  guests: any[] | null
  hosts: HostDto[]
  hot: boolean
  code: string | null
  privacyType: 'PRIVATE' | 'PUBLIC' | 'FOLLOWERS'
  creationType: 'DEFAULT' | 'OPENING_HOUR' | 'EXTERNAL'
  joinOptions: JoinOptionDto[]
  sourceUrl: string | null
  media: Media[]
  hasJoinOptions: boolean
  myGuest: GuestDto | null
  sharerGuest: any | null
  hype: EventHypeDto | null
  tags: string[] | null
  externalTicketInfo: {
    url: string | null
  } | null
  state: 'CANCELED' | any
  theme: any | null
  statusCountsHost: StatusCounts | null
  totalTicketCapacity: number | null
  eventixEventUid: string | null
}

interface StatusCounts {
  joined: number | null
  going: number | null
  maybe: number | null
  cant: number | null
  gaveAvailability: number | null
  ticketsSold: number | null
  totalGoing: number | null
  waitingList: number | null
  invited: number | null
  hyped: number | null
}

interface EventHypeDto {
  hypeScore: number
  hypeScoreCurrent: number
  hot: boolean
  threshold: number
}

interface Media {
  type: 'IMAGE' | 'VIDEO' | 'AUDIO' | 'FILE'
  content: Image | Video
}

export interface Image {
  imageUrls: ImageUrls | null
  ratio: number
}

export interface Video {
  thumbnail: UrlAndStatus
  ratio: number
}

interface UrlAndStatus {
  url: string
  status: any
}

export interface ImageUrls {
  cardSm: string | null
  md: string | null
  lg: string | null
  original: string | null
}

export interface HostDto {
  type: 'USER' | 'ORGANISATION'
  model: UserDto | OrganisationDto
  // model: {
  //   name: string
  //   avatarUrls: ImageUrls | null
  //   username: string | null
  //   id: number | null
  //   manager: boolean | null
  //   followerCount: number | null
  // }
  role: 'CO_HOST' | 'OWNER'
  id: number | null
  state: 'PENDING' | 'ACTIVE'
}

export interface TagDto {
  name: string
  eventCountFuture?: number | null
  eventCountPast?: number | null
  eventCountByOrgFuture?: number | null
  eventCountByOrgPast?: number | null
}

export interface EventAndPostDto {
  event: EventDto
  post: any | null
}

export interface ParsedEventDto {
  title: string | null
  location: string | null
  startDate: string | null
  endDate: string | null
  imageUrl: string | null
  description: string | null
  coordinates: any | null
  placeName: string | null
  address: string | null
}

export interface IdInfo {
  id: number
  uid?: string
  source?: string
}

export interface Translations {
  [key: string]: string
}

export interface QuestionOption {
  id: string
  translated: string
}

export type FormQuestionType =
  | 'SHORT_TEXT'
  | 'RADIO'
  | 'CHECKBOX'
  | 'EMAIL_ADDRESS'
  | 'PHONE_NUMBER'

export type ValidationTypes = 'BEFORE_TODAY'

export interface EventFormQuestionDto extends ExtendedQuestionDto {
  type: ExtendedFormQuestionType
  properties?: QuestionOption[]
  validations?: ValidationTypes[]
  idInfo: any
  caption: string
  translations: Translations
  profile: boolean
}

export enum ProfileQuestionType {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  FULL_NAME = 'FULL_NAME',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  AGE = 'AGE',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  STREET = 'STREET',
  HOUSE_NUMBER = 'HOUSE_NUMBER',
  HOUSE_NUMBER_ADDITION = 'HOUSE_NUMBER_ADDITION',
  POSTAL_CODE = 'POSTAL_CODE',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  STATE = 'STATE',
  PROVINCE = 'PROVINCE',
  COMPANY_NAME = 'COMPANY_NAME',
  GENDER = 'GENDER',
}

export interface JoinOptionQuestionDto {
  id: number
  isNew: boolean | null
  position: number
  required: boolean
  responseCount?: number
  question: EventFormQuestionDto
  questionId?: number
}

export interface CreateQuestionDto {
  translations: Translations
  type: FormQuestionType
  properties?: PatchQuestionOptionDto[]
}

export interface CreateJoinOptionQuestionDto {
  position?: number
  required: boolean
  questionId?: number
  question?: CreateQuestionDto
}

export interface PatchQuestionOptionDto {
  id?: string
  translations: Translations
}

export interface PatchEventFormQuestionDto {
  id?: number
  type?: FormQuestionType
  properties?: PatchQuestionOptionDto[]
  translations?: Translations
}

export function idInfoIsEqual(a?: IdInfo | null, b?: IdInfo | null): boolean {
  if (!a || !b) {
    return false
  }
  return a.id === b.id && a.uid === b.uid && a.source === b.source
}

export interface Event {
  id: number
  code: string
  name: string
  media?: any
  imageUrls?: Record<string, string>
  videoUrl?: string
  sourceUrl?: string
  privacyType: string
  hidden?: boolean
  hosts: Array<{
    id: number
    name: string
  }>
  joinOptions: JoinOptionDto[]
}

export interface JoinOptionDto {
  id: number
  idInfo: IdInfo
  ticketOption: {
    id: number
    price: number
    chooseYourOwnPrice: boolean
    approvalRequired: boolean
  }
  questions: JoinOptionQuestionDto[]
  quantity: number
  price?: number
  name: string
  available: boolean
  availableFrom: string | null
  quantityAvailable: number | null
  chooseYourOwnPrice: boolean
  currency: string | null
  hidden: boolean | null
}

export interface JoinOptionError {
  message: string
}

export interface Fees {
  fixed: number
  varyingPromille: number
  varyingPromilleExVat: number
  vat: number
  varyingMin: number | null
  varyingMax: number | null
}

export type CalculatedFees = {
  applied: { total: number }
} & Fees

export interface TicketGuestDto {
  id: number
  status: TicketStatus
  name: string | null
  joinOptionId: number | null
}

export type TicketStatus =
  | 'RESERVED'
  | 'ACTIVE'
  | 'USED'
  | 'REVOKED'
  | 'PENDING'

export type JoinOptionType = 'TICKET' | 'REGISTRATION_INFO' | 'EXTERNAL_TICKET'
